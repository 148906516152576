.Activity {
  background: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-left: 84px;
  position: relative;
  max-width: 800px;
}

.activity-header {
  position: absolute;
  right: -240px;
  left: auto;
  width: 200px;
  top: 0;
  padding: 0;
  padding-top: 14px;
  font-size: 13px;
}

.activity-icon {
  width: 40px;
  height: 40px;
  background: #3d8af7;
  color: white;
  text-align: center;
  line-height: 40px;
  border-radius: 100%;
  font-size: 20px;
  position: absolute;
  left: -62px;
}

.Activity:before {
  content: '';
  width: 0;
  border: 18px solid transparent;
  border-right-color: #ffffff;
  height: 0;
  position: absolute;
  left: -27px;
}

.activities {
  position: relative;
}

button.expand-emails,
button.collapse-emails {
  position: absolute;
  top: 0;
  right: 0;
}

.activity-notes textarea.form-control {
  width: 100% !important;
}

.activity-notes .email-attachment span {
  margin-left: 10px;
}

.activity-notes .attachment {
  display: inline-block;
  position: relative;
}

.activity-notes .email-attachment {
  padding-right: 30px;
}

.activity-notes .attachment span {
  position: absolute;
  right: 14px;
  top: 2px;
  padding: 5px;
  cursor: pointer;
}

.delete-btn {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  color: #dc3545;
  cursor: pointer;
}

.delete-btn i {
  font-size: 1.2rem;
}

.delete-activity {
  position: absolute;
  top: 0;
  right: 5px;
}

.delete-file {
  right: 5px;
}

.note-attachment > ul,
.note-attachment > li {
  list-style-type: none;
  margin: 10px 0;
  padding: 0;
}

.note-attachment > ul > li > .parent-folder > i {
  padding-right: 5px;
}

.note-attachment > ul > li > span > a {
  background: none;
  border: none;
  color: #337ab7;
}
.note-attachment > ul > li > span > a > i {
  padding-right: 5px;
}
